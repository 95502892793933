
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import Link from 'next/link';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`404 - Page Not Found`}</h1>
    <p>{`The requested page could not be found. It may have been removed, temporarily
taken down, or you've just tried to access a page that never existed.`}</p>
    <p>{`You can try `}<Link href="/" mdxType="Link"><a>{`going back to the home page`}</a></Link>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;