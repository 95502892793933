import Layout from '@/components/Layout';
import Meta from '@/components/Meta';

import NotFound from '@/_content/blocks/errorpages/notfound/404.mdx';

import useConfig from '@/hooks/useConfig';

export default function NotFoundPage () {
  const { t } = useConfig();

  return (
    <Layout>
      <Meta
        title={t('errorpage.notfound.meta.title')}
        description={t('errorpage.notfound.meta.description')}
      />
      <NotFound />
    </Layout>
  );
}
